import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e0ce734a = () => interopDefault(import('../pages/application-stored.vue' /* webpackChunkName: "pages/application-stored" */))
const _53fd242d = () => interopDefault(import('../pages/autolaina.vue' /* webpackChunkName: "pages/autolaina" */))
const _618f0b09 = () => interopDefault(import('../pages/autorahoitus.vue' /* webpackChunkName: "pages/autorahoitus" */))
const _67e7a151 = () => interopDefault(import('../pages/cc-new/index.vue' /* webpackChunkName: "pages/cc-new/index" */))
const _44936a07 = () => interopDefault(import('../pages/cc-new/index/kuluttaja.vue' /* webpackChunkName: "pages/cc-new/index/kuluttaja" */))
const _593d4768 = () => interopDefault(import('../pages/cc-new/index/yritys.vue' /* webpackChunkName: "pages/cc-new/index/yritys" */))
const _bc89d01a = () => interopDefault(import('../pages/etutuoterahoitus.vue' /* webpackChunkName: "pages/etutuoterahoitus" */))
const _0e80783b = () => interopDefault(import('../pages/identiteettivakuutus.vue' /* webpackChunkName: "pages/identiteettivakuutus" */))
const _959c1682 = () => interopDefault(import('../pages/iframe.vue' /* webpackChunkName: "pages/iframe" */))
const _d981895a = () => interopDefault(import('../pages/iframe/leadgroup.vue' /* webpackChunkName: "pages/iframe/leadgroup" */))
const _ff200a4a = () => interopDefault(import('../pages/iframe-yrityslaina.vue' /* webpackChunkName: "pages/iframe-yrityslaina" */))
const _9ee0f932 = () => interopDefault(import('../pages/iframe-yrityslaina/yrityslainaheti.vue' /* webpackChunkName: "pages/iframe-yrityslaina/yrityslainaheti" */))
const _303f95c1 = () => interopDefault(import('../pages/jarjestelylaina.vue' /* webpackChunkName: "pages/jarjestelylaina" */))
const _8716f73c = () => interopDefault(import('../pages/joustoluotto.vue' /* webpackChunkName: "pages/joustoluotto" */))
const _61b035ef = () => interopDefault(import('../pages/kilpailuta-lainat.vue' /* webpackChunkName: "pages/kilpailuta-lainat" */))
const _796ca5fe = () => interopDefault(import('../pages/kokemuksia.vue' /* webpackChunkName: "pages/kokemuksia" */))
const _be67f3d6 = () => interopDefault(import('../pages/lainaa.vue' /* webpackChunkName: "pages/lainaa" */))
const _0504ace4 = () => interopDefault(import('../pages/lainaa-1000-euroa.vue' /* webpackChunkName: "pages/lainaa-1000-euroa" */))
const _a5140680 = () => interopDefault(import('../pages/lainaa-10000-euroa.vue' /* webpackChunkName: "pages/lainaa-10000-euroa" */))
const _e1587342 = () => interopDefault(import('../pages/lainaa-11000-euroa.vue' /* webpackChunkName: "pages/lainaa-11000-euroa" */))
const _16caecdb = () => interopDefault(import('../pages/lainaa-15000-euroa.vue' /* webpackChunkName: "pages/lainaa-15000-euroa" */))
const _07516701 = () => interopDefault(import('../pages/lainaa-20000-euroa.vue' /* webpackChunkName: "pages/lainaa-20000-euroa" */))
const _70a6571c = () => interopDefault(import('../pages/lainaa-25000-euroa.vue' /* webpackChunkName: "pages/lainaa-25000-euroa" */))
const _612cd142 = () => interopDefault(import('../pages/lainaa-30000-euroa.vue' /* webpackChunkName: "pages/lainaa-30000-euroa" */))
const _89ef88fa = () => interopDefault(import('../pages/lainaa-40000-euroa.vue' /* webpackChunkName: "pages/lainaa-40000-euroa" */))
const _f6165fec = () => interopDefault(import('../pages/lainaa-5000-euroa.vue' /* webpackChunkName: "pages/lainaa-5000-euroa" */))
const _14e3a5c4 = () => interopDefault(import('../pages/lainaa-50000-euroa.vue' /* webpackChunkName: "pages/lainaa-50000-euroa" */))
const _6ebf1005 = () => interopDefault(import('../pages/lainaa-60000-euroa.vue' /* webpackChunkName: "pages/lainaa-60000-euroa" */))
const _6ecb0b74 = () => interopDefault(import('../pages/lainaa-70000-euroa.vue' /* webpackChunkName: "pages/lainaa-70000-euroa" */))
const _2d954fe8 = () => interopDefault(import('../pages/lainahakemus.vue' /* webpackChunkName: "pages/lainahakemus" */))
const _7ac32ecf = () => interopDefault(import('../pages/lainakumppanit.vue' /* webpackChunkName: "pages/lainakumppanit" */))
const _0d7dca44 = () => interopDefault(import('../pages/lainavertailu.vue' /* webpackChunkName: "pages/lainavertailu" */))
const _04027fc4 = () => interopDefault(import('../pages/luotto.vue' /* webpackChunkName: "pages/luotto" */))
const _34c59821 = () => interopDefault(import('../pages/markkinointikielto.vue' /* webpackChunkName: "pages/markkinointikielto" */))
const _5e179d9d = () => interopDefault(import('../pages/mmkisat.vue' /* webpackChunkName: "pages/mmkisat" */))
const _0c730b86 = () => interopDefault(import('../pages/moottoripyoralaina.vue' /* webpackChunkName: "pages/moottoripyoralaina" */))
const _7550193b = () => interopDefault(import('../pages/oma/index.vue' /* webpackChunkName: "pages/oma/index" */))
const _3d11252c = () => interopDefault(import('../pages/remonttilaina.vue' /* webpackChunkName: "pages/remonttilaina" */))
const _7da43958 = () => interopDefault(import('../pages/sijoituslaina.vue' /* webpackChunkName: "pages/sijoituslaina" */))
const _02086c0e = () => interopDefault(import('../pages/smskielto.vue' /* webpackChunkName: "pages/smskielto" */))
const _5a7fb82c = () => interopDefault(import('../pages/talous/index.vue' /* webpackChunkName: "pages/talous/index" */))
const _04e1b331 = () => interopDefault(import('../pages/tietoja-evasteista.vue' /* webpackChunkName: "pages/tietoja-evasteista" */))
const _6167bb16 = () => interopDefault(import('../pages/venelaina.vue' /* webpackChunkName: "pages/venelaina" */))
const _b9e3076e = () => interopDefault(import('../pages/yhdista-lainat.vue' /* webpackChunkName: "pages/yhdista-lainat" */))
const _0b3975b6 = () => interopDefault(import('../pages/yhdistelylaina.vue' /* webpackChunkName: "pages/yhdistelylaina" */))
const _dee4024e = () => interopDefault(import('../pages/yhteistyokumppanit/index.vue' /* webpackChunkName: "pages/yhteistyokumppanit/index" */))
const _3a1fc939 = () => interopDefault(import('../pages/yleiset-ehdot.vue' /* webpackChunkName: "pages/yleiset-ehdot" */))
const _820370fe = () => interopDefault(import('../pages/yrityksen-rahoitus.vue' /* webpackChunkName: "pages/yrityksen-rahoitus" */))
const _48697d39 = () => interopDefault(import('../pages/yritys.vue' /* webpackChunkName: "pages/yritys" */))
const _a0d5a628 = () => interopDefault(import('../pages/yrityslainaa-5000-euroa.vue' /* webpackChunkName: "pages/yrityslainaa-5000-euroa" */))
const _5300cd2d = () => interopDefault(import('../pages/yrityslainakumppanit.vue' /* webpackChunkName: "pages/yrityslainakumppanit" */))
const _7190a106 = () => interopDefault(import('../pages/yrityslainat.vue' /* webpackChunkName: "pages/yrityslainat" */))
const _540edb90 = () => interopDefault(import('../pages/yritysrahoitus.vue' /* webpackChunkName: "pages/yritysrahoitus" */))
const _318f4468 = () => interopDefault(import('../pages/cc-new/login.vue' /* webpackChunkName: "pages/cc-new/login" */))
const _cff306f0 = () => interopDefault(import('../pages/talous/ala-hae-lainaa-mista-tahansa.vue' /* webpackChunkName: "pages/talous/ala-hae-lainaa-mista-tahansa" */))
const _652a6a9f = () => interopDefault(import('../pages/talous/auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla.vue' /* webpackChunkName: "pages/talous/auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla" */))
const _463855ee = () => interopDefault(import('../pages/talous/essi-ja-jere-hyodynsivat-lainojen-korkokaton.vue' /* webpackChunkName: "pages/talous/essi-ja-jere-hyodynsivat-lainojen-korkokaton" */))
const _b2756c42 = () => interopDefault(import('../pages/talous/haaveiletko-urheilureissusta.vue' /* webpackChunkName: "pages/talous/haaveiletko-urheilureissusta" */))
const _d9948642 = () => interopDefault(import('../pages/talous/hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia.vue' /* webpackChunkName: "pages/talous/hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia" */))
const _5ece2904 = () => interopDefault(import('../pages/talous/kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat.vue' /* webpackChunkName: "pages/talous/kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat" */))
const _c7f07486 = () => interopDefault(import('../pages/talous/katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa.vue' /* webpackChunkName: "pages/talous/katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa" */))
const _fd97dd7c = () => interopDefault(import('../pages/talous/korkokatto-paattyy-joulukuussa.vue' /* webpackChunkName: "pages/talous/korkokatto-paattyy-joulukuussa" */))
const _13a43a5c = () => interopDefault(import('../pages/talous/korkokatto-putosi-kymmeneen-prosenttiin.vue' /* webpackChunkName: "pages/talous/korkokatto-putosi-kymmeneen-prosenttiin" */))
const _37c86213 = () => interopDefault(import('../pages/talous/korkokattoa-jatkettiin-syksyyn.vue' /* webpackChunkName: "pages/talous/korkokattoa-jatkettiin-syksyyn" */))
const _501088b6 = () => interopDefault(import('../pages/talous/kulutusluottojen-korot-laskivat-korkokaton-seurauksena.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-korot-laskivat-korkokaton-seurauksena" */))
const _24738ed3 = () => interopDefault(import('../pages/talous/kulutusluottojen-korot-putosivat-selvasti.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-korot-putosivat-selvasti" */))
const _55af588c = () => interopDefault(import('../pages/talous/kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle" */))
const _652b7547 = () => interopDefault(import('../pages/talous/kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta" */))
const _59e960c4 = () => interopDefault(import('../pages/talous/lainat-kannattaa-yhdistaa-heti.vue' /* webpackChunkName: "pages/talous/lainat-kannattaa-yhdistaa-heti" */))
const _33032c83 = () => interopDefault(import('../pages/talous/lainojen-yhdistaminen-kannatti.vue' /* webpackChunkName: "pages/talous/lainojen-yhdistaminen-kannatti" */))
const _256cf5a6 = () => interopDefault(import('../pages/talous/mita-tekisit-200-eurolla.vue' /* webpackChunkName: "pages/talous/mita-tekisit-200-eurolla" */))
const _efdd965c = () => interopDefault(import('../pages/talous/miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla.vue' /* webpackChunkName: "pages/talous/miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla" */))
const _3483aaac = () => interopDefault(import('../pages/talous/nain-loydat-luotettavan-lainan-edullisella-korolla.vue' /* webpackChunkName: "pages/talous/nain-loydat-luotettavan-lainan-edullisella-korolla" */))
const _64e514be = () => interopDefault(import('../pages/talous/nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista.vue' /* webpackChunkName: "pages/talous/nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista" */))
const _42e2a4a6 = () => interopDefault(import('../pages/talous/oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa.vue' /* webpackChunkName: "pages/talous/oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa" */))
const _0608b605 = () => interopDefault(import('../pages/talous/petri-yhdisti-pienet-lainansa-ja-hammastyi.vue' /* webpackChunkName: "pages/talous/petri-yhdisti-pienet-lainansa-ja-hammastyi" */))
const _f8ef8712 = () => interopDefault(import('../pages/talous/piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta.vue' /* webpackChunkName: "pages/talous/piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta" */))
const _17f0a1e0 = () => interopDefault(import('../pages/talous/pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa.vue' /* webpackChunkName: "pages/talous/pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa" */))
const _62f8feda = () => interopDefault(import('../pages/talous/teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa.vue' /* webpackChunkName: "pages/talous/teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa" */))
const _7704a177 = () => interopDefault(import('../pages/talous/vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan.vue' /* webpackChunkName: "pages/talous/vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan" */))
const _affc3ff2 = () => interopDefault(import('../pages/yhteistyokumppanit/_company.vue' /* webpackChunkName: "pages/yhteistyokumppanit/_company" */))
const _6a8c96f2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/application-stored",
    component: _e0ce734a,
    name: "application-stored"
  }, {
    path: "/autolaina",
    component: _53fd242d,
    name: "autolaina"
  }, {
    path: "/autorahoitus",
    component: _618f0b09,
    name: "autorahoitus"
  }, {
    path: "/cc-new",
    component: _67e7a151,
    name: "cc-new",
    children: [{
      path: "kuluttaja",
      component: _44936a07,
      name: "cc-new-index-kuluttaja"
    }, {
      path: "yritys",
      component: _593d4768,
      name: "cc-new-index-yritys"
    }]
  }, {
    path: "/etutuoterahoitus",
    component: _bc89d01a,
    name: "etutuoterahoitus"
  }, {
    path: "/identiteettivakuutus",
    component: _0e80783b,
    name: "identiteettivakuutus"
  }, {
    path: "/iframe",
    component: _959c1682,
    name: "iframe",
    children: [{
      path: "leadgroup",
      component: _d981895a,
      name: "iframe-leadgroup"
    }]
  }, {
    path: "/iframe-yrityslaina",
    component: _ff200a4a,
    name: "iframe-yrityslaina",
    children: [{
      path: "yrityslainaheti",
      component: _9ee0f932,
      name: "iframe-yrityslaina-yrityslainaheti"
    }]
  }, {
    path: "/jarjestelylaina",
    component: _303f95c1,
    name: "jarjestelylaina"
  }, {
    path: "/joustoluotto",
    component: _8716f73c,
    name: "joustoluotto"
  }, {
    path: "/kilpailuta-lainat",
    component: _61b035ef,
    name: "kilpailuta-lainat"
  }, {
    path: "/kokemuksia",
    component: _796ca5fe,
    name: "kokemuksia"
  }, {
    path: "/lainaa",
    component: _be67f3d6,
    name: "lainaa"
  }, {
    path: "/lainaa-1000-euroa",
    component: _0504ace4,
    name: "lainaa-1000-euroa"
  }, {
    path: "/lainaa-10000-euroa",
    component: _a5140680,
    name: "lainaa-10000-euroa"
  }, {
    path: "/lainaa-11000-euroa",
    component: _e1587342,
    name: "lainaa-11000-euroa"
  }, {
    path: "/lainaa-15000-euroa",
    component: _16caecdb,
    name: "lainaa-15000-euroa"
  }, {
    path: "/lainaa-20000-euroa",
    component: _07516701,
    name: "lainaa-20000-euroa"
  }, {
    path: "/lainaa-25000-euroa",
    component: _70a6571c,
    name: "lainaa-25000-euroa"
  }, {
    path: "/lainaa-30000-euroa",
    component: _612cd142,
    name: "lainaa-30000-euroa"
  }, {
    path: "/lainaa-40000-euroa",
    component: _89ef88fa,
    name: "lainaa-40000-euroa"
  }, {
    path: "/lainaa-5000-euroa",
    component: _f6165fec,
    name: "lainaa-5000-euroa"
  }, {
    path: "/lainaa-50000-euroa",
    component: _14e3a5c4,
    name: "lainaa-50000-euroa"
  }, {
    path: "/lainaa-60000-euroa",
    component: _6ebf1005,
    name: "lainaa-60000-euroa"
  }, {
    path: "/lainaa-70000-euroa",
    component: _6ecb0b74,
    name: "lainaa-70000-euroa"
  }, {
    path: "/lainahakemus",
    component: _2d954fe8,
    name: "lainahakemus"
  }, {
    path: "/lainakumppanit",
    component: _7ac32ecf,
    name: "lainakumppanit"
  }, {
    path: "/lainavertailu",
    component: _0d7dca44,
    name: "lainavertailu"
  }, {
    path: "/luotto",
    component: _04027fc4,
    name: "luotto"
  }, {
    path: "/markkinointikielto",
    component: _34c59821,
    name: "markkinointikielto"
  }, {
    path: "/mmkisat",
    component: _5e179d9d,
    name: "mmkisat"
  }, {
    path: "/moottoripyoralaina",
    component: _0c730b86,
    name: "moottoripyoralaina"
  }, {
    path: "/oma",
    component: _7550193b,
    name: "oma"
  }, {
    path: "/remonttilaina",
    component: _3d11252c,
    name: "remonttilaina"
  }, {
    path: "/sijoituslaina",
    component: _7da43958,
    name: "sijoituslaina"
  }, {
    path: "/smskielto",
    component: _02086c0e,
    name: "smskielto"
  }, {
    path: "/talous",
    component: _5a7fb82c,
    name: "talous"
  }, {
    path: "/tietoja-evasteista",
    component: _04e1b331,
    name: "tietoja-evasteista"
  }, {
    path: "/venelaina",
    component: _6167bb16,
    name: "venelaina"
  }, {
    path: "/yhdista-lainat",
    component: _b9e3076e,
    name: "yhdista-lainat"
  }, {
    path: "/yhdistelylaina",
    component: _0b3975b6,
    name: "yhdistelylaina"
  }, {
    path: "/yhteistyokumppanit",
    component: _dee4024e,
    name: "yhteistyokumppanit"
  }, {
    path: "/yleiset-ehdot",
    component: _3a1fc939,
    name: "yleiset-ehdot"
  }, {
    path: "/yrityksen-rahoitus",
    component: _820370fe,
    name: "yrityksen-rahoitus"
  }, {
    path: "/yritys",
    component: _48697d39,
    name: "yritys"
  }, {
    path: "/yrityslainaa-5000-euroa",
    component: _a0d5a628,
    name: "yrityslainaa-5000-euroa"
  }, {
    path: "/yrityslainakumppanit",
    component: _5300cd2d,
    name: "yrityslainakumppanit"
  }, {
    path: "/yrityslainat",
    component: _7190a106,
    name: "yrityslainat"
  }, {
    path: "/yritysrahoitus",
    component: _540edb90,
    name: "yritysrahoitus"
  }, {
    path: "/cc-new/login",
    component: _318f4468,
    name: "cc-new-login"
  }, {
    path: "/talous/ala-hae-lainaa-mista-tahansa",
    component: _cff306f0,
    name: "talous-ala-hae-lainaa-mista-tahansa"
  }, {
    path: "/talous/auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla",
    component: _652a6a9f,
    name: "talous-auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla"
  }, {
    path: "/talous/essi-ja-jere-hyodynsivat-lainojen-korkokaton",
    component: _463855ee,
    name: "talous-essi-ja-jere-hyodynsivat-lainojen-korkokaton"
  }, {
    path: "/talous/haaveiletko-urheilureissusta",
    component: _b2756c42,
    name: "talous-haaveiletko-urheilureissusta"
  }, {
    path: "/talous/hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia",
    component: _d9948642,
    name: "talous-hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia"
  }, {
    path: "/talous/kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat",
    component: _5ece2904,
    name: "talous-kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat"
  }, {
    path: "/talous/katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa",
    component: _c7f07486,
    name: "talous-katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa"
  }, {
    path: "/talous/korkokatto-paattyy-joulukuussa",
    component: _fd97dd7c,
    name: "talous-korkokatto-paattyy-joulukuussa"
  }, {
    path: "/talous/korkokatto-putosi-kymmeneen-prosenttiin",
    component: _13a43a5c,
    name: "talous-korkokatto-putosi-kymmeneen-prosenttiin"
  }, {
    path: "/talous/korkokattoa-jatkettiin-syksyyn",
    component: _37c86213,
    name: "talous-korkokattoa-jatkettiin-syksyyn"
  }, {
    path: "/talous/kulutusluottojen-korot-laskivat-korkokaton-seurauksena",
    component: _501088b6,
    name: "talous-kulutusluottojen-korot-laskivat-korkokaton-seurauksena"
  }, {
    path: "/talous/kulutusluottojen-korot-putosivat-selvasti",
    component: _24738ed3,
    name: "talous-kulutusluottojen-korot-putosivat-selvasti"
  }, {
    path: "/talous/kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle",
    component: _55af588c,
    name: "talous-kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle"
  }, {
    path: "/talous/kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta",
    component: _652b7547,
    name: "talous-kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta"
  }, {
    path: "/talous/lainat-kannattaa-yhdistaa-heti",
    component: _59e960c4,
    name: "talous-lainat-kannattaa-yhdistaa-heti"
  }, {
    path: "/talous/lainojen-yhdistaminen-kannatti",
    component: _33032c83,
    name: "talous-lainojen-yhdistaminen-kannatti"
  }, {
    path: "/talous/mita-tekisit-200-eurolla",
    component: _256cf5a6,
    name: "talous-mita-tekisit-200-eurolla"
  }, {
    path: "/talous/miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla",
    component: _efdd965c,
    name: "talous-miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla"
  }, {
    path: "/talous/nain-loydat-luotettavan-lainan-edullisella-korolla",
    component: _3483aaac,
    name: "talous-nain-loydat-luotettavan-lainan-edullisella-korolla"
  }, {
    path: "/talous/nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista",
    component: _64e514be,
    name: "talous-nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista"
  }, {
    path: "/talous/oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa",
    component: _42e2a4a6,
    name: "talous-oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa"
  }, {
    path: "/talous/petri-yhdisti-pienet-lainansa-ja-hammastyi",
    component: _0608b605,
    name: "talous-petri-yhdisti-pienet-lainansa-ja-hammastyi"
  }, {
    path: "/talous/piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta",
    component: _f8ef8712,
    name: "talous-piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta"
  }, {
    path: "/talous/pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa",
    component: _17f0a1e0,
    name: "talous-pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa"
  }, {
    path: "/talous/teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa",
    component: _62f8feda,
    name: "talous-teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa"
  }, {
    path: "/talous/vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan",
    component: _7704a177,
    name: "talous-vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan"
  }, {
    path: "/yhteistyokumppanit/:company",
    component: _affc3ff2,
    name: "yhteistyokumppanit-company"
  }, {
    path: "/",
    component: _6a8c96f2,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
